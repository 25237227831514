<template>
  <Topbar></Topbar>
  <router-view/>
  <WhatsappBtn></WhatsappBtn>
  <Footer></Footer>
</template>

<script>

import Topbar from './components/Topbar.vue';
import WhatsappBtn from './components/WhatsappBtn.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Topbar,
    WhatsappBtn,
    Footer
  }
}
</script>

<style>
</style>
