<template>
<v-app>
    <div id="simulador-de-credito">
        <v-form id="dados-cliente">
            <v-label><h1>Olá!</h1></v-label><br/>
            <div id="simulador-title">
                <svg-icon type="mdi" :path="path" id="money-img"></svg-icon>
                <h2>Crédito Imobilário</h2>
            </div>
            <v-label><p>Antes de fazer a simulação, nos informe alguns dados.</p></v-label>
            <v-text-field color="blue-darken-4" label="Nome completo">
            </v-text-field>
            <v-text-field color="blue-darken-4" label="E-mail">
            </v-text-field>
            <v-text-field color="blue-darken-4" label="CEP">
            </v-text-field>
            <v-switch color="blue-darken-4" label="Aceito os Termos e Condições"></v-switch>
            <v-btn type="submit" color="blue-darken-4" form="dados-cliente" to="/simulador-credito">Próximo</v-btn>
        </v-form>
    </div>
</v-app>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCashMultiple } from '@mdi/js';
export default {
  components: {
    SvgIcon
  },
  data() {
    return {
       path: mdiCashMultiple,
    }
  }
}
</script>
<style scoped>
#simulador-de-credito {
    margin-top: 100px;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
}

v-btn[type="submit"] {
    display: inline;
}

#money-img {
    width: 48px;
    height: 48px;
    color: white;
    background-color: rgb(0, 25, 136);
    padding: 5px;
    border-radius: 1rem
}

#simulador-title {
    display: inline-block;
    width: 500px;
    vertical-align: middle;
}


</style>