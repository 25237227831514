<template>
<v-app>
    <div id="login-container">
        <h1 id="empresa-name">{{ empresa_name }} - Área do Corretor</h1>
        <div id="credenciais-input">
            <v-text-field v-model="user" color="blue-darken-4" label="Usuário"></v-text-field>
            <v-text-field v-model="passw" color="blue-darken-4" label="Senha" type="password"></v-text-field>
            <v-switch color="blue-darken-4" label="Manter logado"></v-switch>
        </div>
        <v-btn type="submit" color="blue-darken-4" @click="login()">Entrar</v-btn>
    </div>
</v-app>    
</template>
<script>
import axios from 'axios'

export default {
    data() {
        return {
            empresa_name: "A2 Consultoria",
            user: "",
            passw: "",
        }
    },
    methods: {
        login: function () {
            alert("Em breve.")
        }
    }

}
</script>
<style scoped>
#login-container {
    margin-top: 0px;
    width: 700px;
    margin: auto;
}



#empresa-name {
    font-family: Poppins;
    margin-bottom: 1rem;
}

</style>