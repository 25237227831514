<template>
<v-app>
    <div id="simulador-credito">
            <div id="containers">
                <div id="simulador-container">
                    <h1><svg-icon type="mdi" :path="path" id="money-img"></svg-icon> Simulador Crédito Imobiliário</h1>
                    <div id="slider">
                        <p>Sua renda mensal (R$)</p>
                        <v-slider
                            v-model="sliderInputValue"
                            min="3000"
                            max="8000"
                            step="1000"
                            show-ticks="always"
                            thumb-label="always"
                            thumb-color="blue-darken-4"
                            track-color="blue"
                            color="blue-darken-4"
                        >
                        </v-slider>
                    </div>
                    <v-switch v-model="fgts" v-on:change=updateResumeTable color="blue-darken-4" label="Possui 3 anos de FGTS?">
                    </v-switch>
                </div>
                <!-- sempre que for atualizar os dados da tabela no excel alterar o schema json no valor_total-->
                <div id="simulador-resultado-container">
                    <h1>Resumo</h1>
                    <p>Valor total: <b>{{ simulador_valores["valor_imovel"][valorImovelSelected]["fgts"][fgts]["renda_cliente"][sliderInputValue]["valor_liberado"].toLocaleString("pt-br", {style: "currency", currency: "BRL"}) }}</b></p>
                    <p>Taxa de juros: <b>{{ simulador_valores["valor_imovel"][valorImovelSelected]["fgts"][fgts]["renda_cliente"][sliderInputValue]["juros"] }}</b></p>
                    <p>1° parcela: <b>{{ simulador_valores["valor_imovel"][valorImovelSelected]["fgts"][fgts]["renda_cliente"][sliderInputValue]["valor_primeira_parcela"].toLocaleString("pt-br", {style: "currency", currency: "BRL"}) }}</b></p>
                    <v-btn style="margin-top: 1rem" class=btn href='https://api.whatsapp.com/send?phone=551335917095&text='  target="_blank">Entrar em contato</v-btn>
                </div>
            </div>
    </div>
</v-app>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCashMultiple } from '@mdi/js';
import axios from "axios";
import { onMounted } from 'vue';

export default {
    components: {
        SvgIcon,
    }, 
    created() {
        this.updateResumeTable();
    },
    data () {
        return {
            api_hostname: "http://localhost:1337/api/v1/get-simulacao-values",
            path: mdiCashMultiple,
            sliderInputValue: 3000,
            valorImovelSelected: 264000,
            tabela_amortizacao: "",
            fgts: true,
            simulador_valores: {
                "valor_imovel": {
                    "264000": {

                    "fgts": {
                        "true": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "5,12%",
                                "valor_liberado": "R$158.094,89",
                                "valor_primeira_parcela": "R$883,11"
                                },
                                "3500": {
                                "juros": "5,64%",
                                "valor_liberado": "R$175.693,52",
                                "valor_primeira_parcela": "R$1033,11"
                                },
                                "4000": {
                                "juros": "6,70%",
                                "valor_liberado": "R$180.787,34",
                                "valor_primeira_parcela": "R$1.183,10"
                                },
                                "4500": {
                                "juros": "7,93%",
                                "valor_liberado": "R$181.149,22",
                                "valor_primeira_parcela": "R$1.333,1"
                                },
                                "5000": {
                                "juros": "7,93%",
                                "valor_liberado": "R$202.258,08",
                                "valor_primeira_parcela": "R$1.483,11"
                                },
                                "5500": {
                                "juros": "7,93%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.546,64"
                                },
                                "6000": {
                                "juros": "7,93%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.546,64"
                                },
                                "7000": {
                                "juros": "7,93%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.546,64"
                                },
                                "8000": {
                                "juros": "7,93%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.546,64"
                                }
                            }
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "5.64%",
                                "valor_liberado": "R$148.999,13",
                                "valor_primeira_parcela": "R$883,11"
                                },
                                "3500": {
                                "juros": "6.17%",
                                "valor_liberado": "R$165.899,55",
                                "valor_primeira_parcela": "R$1.033,1"
                                },
                                "4000": {
                                "juros": "7.23%",
                                "valor_liberado": "R$171.335,55",
                                "valor_primeira_parcela": "R$1.183,11"
                                },
                                "4500": {
                                "juros": "8.47%",
                                "valor_liberado": "R$172.366,23",
                                "valor_primeira_parcela": "R$1.333,11"
                                },
                                "5000": {
                                "juros": "8.47%",
                                "valor_liberado": "R$192.451,63",
                                "valor_primeira_parcela": "R$1.483,11"
                                },
                                "5500": {
                                "juros": "8.47%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.623,12"
                                },
                                "6000": {
                                "juros": "8.47%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.623,12"
                                },
                                "7000": {
                                "juros": "8.47%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.623,12"
                                },
                                "8000": {
                                "juros": "8.47%",
                                "valor_liberado": "R$211.200",
                                "valor_primeira_parcela": "R$1.623,12"
                                }
                            }
                        }
                
                    },
                    "tabela": "PRICE"
                    },
                    "270000": {
                    "fgts": {
                        "true": {
                        "renda_cliente": {
                            "3000": {
                            "juros": "9.01%",
                            "valor_liberado": "87595.88",
                            "valor_primeira_parcela": "732.72"
                            },
                            "3500": {
                            "juros": "9.01%",
                            "valor_liberado": "103548.17",
                            "valor_primeira_parcela": "857.72"
                            },
                            "4000": {
                            "juros": "9.01%",
                            "valor_liberado": "119500.45",
                            "valor_primeira_parcela": "982.72"
                            },
                            "4500": {
                            "juros": "7.93%",
                            "valor_liberado": "180826.96",
                            "valor_primeira_parcela": "1332.08"
                            },
                            "5000": {
                            "juros": "7.93%",
                            "valor_liberado": "201935.82",
                            "valor_primeira_parcela": "1482.08"
                            },
                            "5500": {
                            "juros": "7.93%",
                            "valor_liberado": "223044.68",
                            "valor_primeira_parcela": "1632.08"
                            },
                            "6000": {
                            "juros": "7.93%",
                            "valor_liberado": "224000",
                            "valor_primeira_parcela": "1638.87"
                            },
                            "7000": {
                            "juros": "7.93%",
                            "valor_liberado": "224000",
                            "valor_primeira_parcela": "1638.87"
                            },
                            "8000": {
                            "juros": "7.93%",
                            "valor_liberado": "224000",
                            "valor_primeira_parcela": "1638.87"
                            }
                        },
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "3500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "6000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "7000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "8000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                }
                            }
                        }
                        
                    },
                    "tabela": "PRICE"
                    },
                    "280000": {
                    "fgts": {
                        "true": {
                        "renda_cliente": {
                            "3000": {
                            "juros": "9.01%",
                            "valor_liberado": "87413.39",
                            "valor_primeira_parcela": "732.08"
                            },
                            "3500": {
                            "juros": "9.01%",
                            "valor_liberado": "103365.67",
                            "valor_primeira_parcela": "857.08"
                            },
                            "4000": {
                            "juros": "9.01%",
                            "valor_liberado": "119317.95",
                            "valor_primeira_parcela": "982.08"
                            },
                            "4500": {
                            "juros": "7.93%",
                            "valor_liberado": "181028.19",
                            "valor_primeira_parcela": "1332.72"
                            },
                            "5000": {
                            "juros": "7.93%",
                            "valor_liberado": "202137.06",
                            "valor_primeira_parcela": "1482.72"
                            },
                            "5500": {
                            "juros": "7.93%",
                            "valor_liberado": "216000",
                            "valor_primeira_parcela": "1581.23"
                            },
                            "6000": {
                            "juros": "7.93%",
                            "valor_liberado": "216000",
                            "valor_primeira_parcela": "1581.23"
                            },
                            "7000": {
                            "juros": "7.93%",
                            "valor_liberado": "216000",
                            "valor_primeira_parcela": "1581.23"
                            },
                            "8000": {
                            "juros": "7.93%",
                            "valor_liberado": "216000",
                            "valor_primeira_parcela": "1581.23"
                            }
                        },
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "3500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "6000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "7000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "8000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                }
                            }
                        }
                    },
                    "tabela": "PRICE"
                    },
                    "300000": {
                    "fgts": {
                        "true": {
                        "renda_cliente": {
                            "3000": {
                            "juros": "9.01%",
                            "valor_liberado": "87048.4",
                            "valor_primeira_parcela": "730.81"
                            },
                            "3500": {
                            "juros": "9.01%",
                            "valor_liberado": "103000.68",
                            "valor_primeira_parcela": "855.8"
                            },
                            "4000": {
                            "juros": "9.01%",
                            "valor_liberado": "118952.96",
                            "valor_primeira_parcela": "980.8"
                            },
                            "4500": {
                            "juros": "7.93%",
                            "valor_liberado": "180424.48",
                            "valor_primeira_parcela": "1330.8"
                            },
                            "5000": {
                            "juros": "7.93%",
                            "valor_liberado": "201533.34",
                            "valor_primeira_parcela": "1480.8"
                            },
                            "5500": {
                            "juros": "7.93%",
                            "valor_liberado": "222642.21",
                            "valor_primeira_parcela": "1630.8"
                            },
                            "6000": {
                            "juros": "7.93%",
                            "valor_liberado": "240000",
                            "valor_primeira_parcela": "1754.15"
                            },
                            "7000": {
                            "juros": "7.93%",
                            "valor_liberado": "240000",
                            "valor_primeira_parcela": "1754.15"
                            },
                            "8000": {
                            "juros": "7.93%",
                            "valor_liberado": "240000",
                            "valor_primeira_parcela": "1754.15"
                            }
                        },
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "3500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "6000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "7000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "8000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                }
                            }
                        }
                    },
                    "tabela": "PRICE"
                    },
                    "330000": {
                    "fgts": {
                        "true": {
                        "renda_cliente": {
                            "3000": {
                            "juros": "9.01%",
                            "valor_liberado": "86683.41",
                            "valor_primeira_parcela": "729.52"
                            },
                            "3500": {
                            "juros": "9.01%",
                            "valor_liberado": "102635.69",
                            "valor_primeira_parcela": "854.52"
                            },
                            "4000": {
                            "juros": "9.01%",
                            "valor_liberado": "118587.98",
                            "valor_primeira_parcela": "979.52"
                            },
                            "4500": {
                            "juros": "7.93%",
                            "valor_liberado": "180022",
                            "valor_primeira_parcela": "1329.52"
                            },
                            "5000": {
                            "juros": "7.93%",
                            "valor_liberado": "201130.87",
                            "valor_primeira_parcela": "1479.52"
                            },
                            "5500": {
                            "juros": "7.93%",
                            "valor_liberado": "222239.73",
                            "valor_primeira_parcela": "1629.52"
                            },
                            "6000": {
                            "juros": "7.93%",
                            "valor_liberado": "243348.59",
                            "valor_primeira_parcela": "1754.15"
                            },
                            "7000": {
                            "juros": "7.93%",
                            "valor_liberado": "256000",
                            "valor_primeira_parcela": "1869.42"
                            },
                            "8000": {
                            "juros": "7.93%",
                            "valor_liberado": "256000",
                            "valor_primeira_parcela": "1869.42"
                            }
                        },
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "3500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "6000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "7000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "8000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                }
                            }
                        }
                    },
                    "tabela": "PRICE"
                    },
                    "350000": {
                    "fgts": {
                        "true": {
                        "renda_cliente": {
                            "3000": {
                            "juros": "9.01%",
                            "valor_liberado": "86135.93",
                            "valor_primeira_parcela": "727.6"
                            },
                            "3500": {
                            "juros": "9.01%",
                            "valor_liberado": "102088.21",
                            "valor_primeira_parcela": "852.6"
                            },
                            "4000": {
                            "juros": "9.01%",
                            "valor_liberado": "118040.49",
                            "valor_primeira_parcela": "977.6"
                            },
                            "4500": {
                            "juros": "7.93%",
                            "valor_liberado": "179418.29",
                            "valor_primeira_parcela": "1327.61"
                            },
                            "5000": {
                            "juros": "7.93%",
                            "valor_liberado": "200527.15",
                            "valor_primeira_parcela": "1477.6"
                            },
                            "5500": {
                            "juros": "7.93%",
                            "valor_liberado": "221636.02",
                            "valor_primeira_parcela": "1627.6"
                            },
                            "6000": {
                            "juros": "7.93%",
                            "valor_liberado": "242744.88",
                            "valor_primeira_parcela": "1777.6"
                            },
                            "7000": {
                            "juros": "7.93%",
                            "valor_liberado": "280000",
                            "valor_primeira_parcela": "2042.34"
                            },
                            "8000": {
                            "juros": "7.93%",
                            "valor_liberado": "280000",
                            "valor_primeira_parcela": "2042.34"
                            }
                        },
                        },
                        "false": {
                            "renda_cliente": {
                                "3000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "3500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "4500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "5500": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "6000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "7000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                },
                                "8000": {
                                "juros": "",
                                "valor_liberado": "",
                                "valor_primeira_parcela": ""
                                }
                            }
                        }
                    },
                    "tabela": "PRICE"
                    },
                }
                },
            valor_total: "",
            taxa_de_juros: "",
            valor_primeira_parcela: "",

        }
    },
    methods: {
        updateResumeTable() {
            // if(this.fgts == true) {
            //     axios.get(this.api_hostname).then((res) => {
            //         this.simulador_valores = res.data
            //     })
            // } else if (this.fgts == false) {

            // }
            }
        },
    }




</script>

<style scoped>



#money-img {
    height: 40px;
    width: 40px;
    color: white;
    background-color: rgb(0, 25, 136);
    padding: 5px;
    border-radius: 0.5rem;
    vertical-align: middle;
}

#simulador-credito {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

#containers {
}

.btn {
        background-color: rgb(0, 25, 136);
        color: white;
        font-weight: bold;
    }
.btn:hover {
    background-color: rgb(255, 145, 0);

}

#simulador-resultado-container button {
    color: white;
}
#simulador-container { 
    margin-left: 2.5rem;
    margin-right: auto;
    width: 600px;
    display: inline-block;
    vertical-align: top;

}

#simulador-resultado-container {
    margin-left: 20rem;
    width: auto;
    padding: 2rem;
    border-radius: 1rem;
    display: inline-block;
    vertical-align: top;
    background-color: rgba(0, 0, 0, 0.1)
}

@media (max-width: 460px) {
    #simulador-resultado-container {
        margin: 3rem;
        width: auto;
        display: block;
    }

    #simulador-container {
        width: 300px;
        margin: auto;
        display: block;
    }


    #simulador-credito {
        margin: auto;
        width: 100%;
    }
}

</style>