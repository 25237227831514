<template>
<header id="header">
    <router-link id="logo" to="/"><img src="https://media.discordapp.net/attachments/330029589579563009/1118304554484777081/logo.png?width=445&height=445"/></router-link>
    <nav id="navbar">
        <button id="btn-mobile" @click="toggleMenu()">Menu</button>
        <ul id="menu">
            <li id="dropdown1">
                <a href="/empresa" class="dropdown">A empresa A2 <svg style="height: 16px; width: 16px; vertical-align: middle;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg></a>
                <ul class="dropdown">
                    <li><router-link to="/empresa">Quem somos</router-link></li>
                    <li><router-link to="/localizacao">Localização</router-link></li>
                    <li><router-link to="/contato">Contato</router-link></li>
                    <li><router-link to="/certificados">Certificações</router-link></li>
                </ul>
            </li>
            <li id="dropdown2">
                <a class="dropdown">Serviços <svg style="height: 16px; width: 16px; vertical-align: middle;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>chevron-down</title><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg></a>
                <ul class="dropdown">
                    <li><router-link to="/credito-imobiliario">Crédito Imobiliário</router-link></li>
                    <li><router-link to="/credito-consignado">Crédito Consignado</router-link></li>
                    <li><router-link to="/consorcio">Consórcio Imóveis</router-link></li>
                    <li><router-link to="/consorcio">Consórcio Veículos</router-link></li>
                    <li><router-link to="/abertura-de-contas">Abertura de Contas</router-link></li>
                    <li><router-link to="/cartao-de-credito">Cartão de crédito</router-link></li>
                </ul>
            </li>
            <li class=mobile-only><router-link to="/empresa">Quem somos</router-link></li>
            <li class=mobile-only><router-link to="/localizacao">Localização</router-link></li>
            <li class=mobile-only><router-link to="/contato">Contato</router-link></li>
            <li class=mobile-only><router-link to="/certificados">Certificações</router-link></li>
            <li class=mobile-only><router-link to="/credito-imobiliario">Crédito Imobiliário</router-link></li>
            <li class=mobile-only><router-link to="/credito-consignado">Crédito Consignado</router-link></li>
            <li class=mobile-only><router-link to="/consorcio">Consórcio</router-link></li>
            <li class=mobile-only><router-link to="/abertura-de-contas">Abertura de Contas</router-link></li>
            <li class=mobile-only><router-link to="/cartao-de-credito">Cartão de crédito</router-link></li>
            <li><router-link to="/contato" class="menu-routes">Fale Conosco</router-link></li>
            <li><router-link to="/simulador-credito" class="menu-routes">Simulador de Crédito Imobiliário</router-link></li>
            <v-btn id="corretor-btn" v-if="!mobile" class="btn" to="/login-corretor">Área do Corretor</v-btn>
        </ul>
    </nav>
</header>
</template>
<script>
export default {
    data() {
        return {
            logo: require("../assets/logo.png")
        }
    },
    methods: {
        toggleMenu: function () {
            const navbar = document.getElementById("navbar");

            if(navbar.classList.contains("active")) {
                navbar.classList.remove("active");
            } else {
                navbar.classList.add("active");
            }
        }
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.dropdown {
    cursor: pointer;
}

body, ul {
    margin: 0px;
    padding: 0px;
} 

#corretor-btn {
    margin-top: 0.8rem;
}

#logo img {
    width: 60px;
    height: 60px;
    vertical-align: bottom;
}

.btn {
  background-color: rgb(0, 25, 136) !important;
  color: white !important;
  font-weight: bold !important;
  width: 250px;
}
.btn:hover {
  background-color: rgb(255, 145, 0) !important;

}

#header {
    z-index: 9999999999999999999999999999999999; /* sobrepor qualquer objeto */
    box-sizing: border-box;
    width: 100%;
   display: flex; 
   height: 70px;
   padding: 1rem;
   border-bottom: 2px solid rgb(255, 140, 0);
   align-items: center;
   justify-content: space-between;
   background-color: rgb(255, 255, 255);
}

#menu {
    display: flex;
    list-style: none;
    gap: .8rem;

}

ul li {
    display: inline-block;
    position: relative;
}

ul li a {
    display: block;
    padding: 20px 25px;
    text-decoration: none;
}

ul li ul.dropdown li {
    display: block;
}

ul li ul.dropdown {
    width: 100%;
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 999999999999;
    display: none;
    border-bottom: 2px solid rgb(0, 25, 136);
}

ul li ul.dropdown a {
    color: rgb(0, 25, 136);
}
ul li:hover ul.dropdown {
    display: block;
    width: 200px;
}

ul li:hover a:hover {
    color: rgb(255, 140, 0);
}

a {
    display: block;
    font-size: 16px;
    height: auto;
    text-decoration: none;
    list-style: none;
    padding: .5rem;
    color: rgb(0, 12, 142);
}

a:hover {
    color: rgb(255, 140, 0);
}

#btn-mobile {
    display: none;
}

#a-empresa {
    margin-right: 30rem;
}


.mobile-only {
    display: none;
}

@media (max-width: 460px) {
    .mobile-only {
        display: block;
    }
    #btn-mobile {
        display: block;
    }

    #corretor-btn {
        display: none;
    }

    #header {
        display: static;
    }

    #navbar.active #menu {
        display: block;
        height: calc(100vh + 70px);
    }
    #menu {
        position: absolute;
        width: 100%;
        z-index: 99999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
        right: 0px;
        top: 70px;
        background-color: rgb(0, 25, 136);
        display: none;
        transition: 0.6s;
    }
    #dropdown1, #dropdown2 {
        display: none;
    }
    #menu a {
        color: rgb(255, 145, 0);
    }
}
</style>