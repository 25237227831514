<template>
<v-app>
    <div id=cartoes>
        <div id="main-header">
            <div id="img-text">
                <h1>Cartão de Crédito</h1>
                <p>Temos um cartão certo para o que você precisar.</p>
                <v-btn class="btn" target="_blank" href="https://api.whatsapp.com/send?phone=551335917095&text=Ol%C3%A1,%20quero%solicitar%20um%cartão%20caixa.">Solicite agora o seu!</v-btn>
            </div>
            <img id=main-img src="https://cdn.discordapp.com/attachments/1101250377950707794/1136756889040986224/Cartao_Visa_Elo-modified.png"> 
        </div>
    </div>
    <div id=content>
        <div class=container>
            <h1>Cartão de Crédito CAIXA</h1>
            <p>É um cartão de crédito personalizado, na bandeira ELO ou VISA, sem anuidade para sempre! E você ainda pode solicitar cartão adicional sem custo. Pode ser usado no Brasil e no exterior, em compras à vista ou parceladas. </p>
            <p>Para compras parceladas, os pagamentos poderão ser feitos com ou sem juros, dependendo das condições oferecidas pelo estabelecimento. </p>
            <p>Baixe e use o aplicativo Cartões CAIXA para gerenciar seu cartão, acessar informações e serviços e gerar cartão de crédito virtual, para realizar compras em aplicativos ou na internet com mais segurança. </p>
            <p>Envio de SMS gratuito com as compras realizadas. Pode ser contratado no aplicativo Cartões CAIXA, autoatendimento, agências, lotéricas ou correspondentes CAIXA Aqui.</p>
        </div>
        <div class="container">
            <h1>Conheça alguns benefícios do cartão de crédito CAIXA Visa</h1>
            <ul>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Garantia estendida</span> - Garantia do produto que você comprar com o cartão VISA por mais tempo do que o dado pelo fabricante.</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Proteção de preço</span> - Se a cliente encontrar um produto mais barato em outro lugar, a diferença é ressarcida.</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Protedação de compra</span> - Existe proteção contra roubo ou danos acidentais.</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Visa Causas</span> - Vai de Visa e escolha a causa que deseja apoiar sem pagar nada a mais por isso.</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Vai de Visa</span> - Vai de Visa para aproveitar benefícios únicos e especiais, como você.</li>
            </ul>
        </div>
        <div class="container">
            <h1>Conheça alguns benefícios do cartão de crédito CAIXA ELO</h1>
            <ul>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Corpo e Mente</span> - serviços de orientação nutricional, fitness, apoio emocional e orientações de saúde</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">EduK</span> - plataforma de ensino on-line para aprender a empreender, gastronomia, artesanato, fotografia. São mais de 1.300 cursos profissionalizantes do básico ao avançado</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">CNA Go</span> - curso de idiomas 100% digital em uma plataforma fácil de usar</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Bônus de celular</span> - para navegar na internet, fazer ligações e/ou enviar SMS</li>
                <li><span style="color: rgb(0, 25, 136); font-weight: bolder">Voucher Wi-fi</span> - para ser utilizado em vários pontos de conexão no Brasil</li>
            </ul>
            
        </div>
    </div>
</v-app>
</template>
<script>

</script>

<style scoped>

.btn {
    background-color: rgb(0, 25, 136);
    color: white;
    font-weight: bold;
}
.btn:hover {
    background-color: rgb(255, 145, 0);

}
#main-img {
    margin-top: 0px;
    width: 100%;
    height: 400px;
}

#img-text {
    position: absolute;
    margin: 2rem;
    margin-top: 12rem;
}

#img-text h1{
    color: rgb(255, 145, 0);
}

#img-text p {
    color: white;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.container {
    width: auto;
        height: auto;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

.container h1 {
    font-size: 34px;
    margin-top: 1.5rem;
    text-align: center;
}

.container p {
    text-align: left;
    width: 50%;
    font-size: 20px;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1rem;
}

.container ul {
    margin: auto;
}

.container li {
    margin: auto;
    list-style: none;
    width: 50%;
    margin-top: 1rem;
    font-size: 20px;
    text-align: left;
}

@media (max-width: 460px) {

        .container p {
            width: auto;
            padding: 1rem;
        }

        .container li {
            width: auto;
            padding: 1rem;
        }
        .container h1 {
            font-size: 24px;
            padding: 1rem;
        }
        #main-img{
        height: 145px;
        }

        #img-text {
            margin-top: 4.5rem;
        }

        #img-text h1 {
            font-size: 20px;
        }

        #img-text p {
            font-size: 16px;
        }
    }
</style>