import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ConsorcioView from '../views/ConsorcioView.vue'
import EmpresaView from '../views/EmpresaView.vue'
import LocalizacaoView from '../views/LocalizacaoView.vue'
import ContatoView from '../views/ContatoView.vue'
import CertificacoesView from '../views/CertificacoesView.vue'
import SimuladorCreditoView from '../views/SimuladorCreditoView.vue'
import SimuladorDadosClienteView from '../views/SimuladorDadosClienteView.vue'
import LoginCorretorView from '../views/LoginCorretor.vue'
import CreditoImobiliarioView from '../views/CreditoImobiliarioView.vue'
import CartaoView from '../views/CartaoView.vue'
import AberturaContasView from '../views/AberturaContasView.vue'
import CreditoConsignadoView from '../views/CreditoConsignadoView.vue'
import CorretorDashboard from '../views/CorretorDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: EmpresaView,
  },
  {
    path: '/consorcio',
    name: 'consorcio',
    component: ConsorcioView

  },
  {
    path: '/localizacao',
    name: 'localizacao',
    component: LocalizacaoView
  },
  {
    path: '/contato',
    name: 'contato',
    component: ContatoView
  },
  {
    path: '/certificados',
    name: 'certificados',
    component: CertificacoesView
  },
  {
    path: '/simulador-credito',
    name: 'simulador-credito',
    component: SimuladorCreditoView
  },
  {
    path: '/dados-cliente',
    name: 'simulador-credito-dados',
    component: SimuladorDadosClienteView,
  },
  {
    path: '/login-corretor',
    name: 'login-corretor',
    component: LoginCorretorView,
  },
  {
    path: '/credito-imobiliario',
    name: 'credito-imobiliario',
    component: CreditoImobiliarioView,
  },
  {
    path: '/cartao-de-credito',
    name: 'cartao-de-credito',
    component: CartaoView,
  },
  {
    path: '/abertura-de-contas',
    name: 'abertura-de-contas',
    component: AberturaContasView,
  },
  {
    path: '/credito-consignado',
    name: 'credito-consignado',
    component: CreditoConsignadoView,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: CorretorDashboard,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
