<template lang="">
<div id="consorcio-page" class="content">
  <div id="main-header">
            <div id="img-text">
                <h1>Consórcio</h1>
                <p id="cred-description">Temos a opção de consórcio ideal pra você.</p>
                <v-btn to="/simulador-credito" class="btn">Simule aqui seu consórcio</v-btn>
            </div>
            <img id=main-img src="https://cdn.discordapp.com/attachments/1101250377950707794/1130614223089696852/Banner_Consorcio_3-modified.png"> 
        </div>
    <div id="all-containers">
      <div id="entenda-consorcio">
        <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1137043373841186918/business.png" width="240px" height="240px">
        <h1>Entenda o consórcio</h1>
        <p>O consórcio é uma modalidade de compra coletiva, onde um grupo de pessoas se compromete a pagar uma parcela mensal, por um tempo determinado. Esse dinheiro é guardado em um fundo comum e, todo mês, alguns integrantes do grupo são escolhidos (por sorteio e por lance) para receber o valor do crédito e comprar o bem.</p>
        <p>Conquiste sua casa, carro, ônibus, caminhão ou máquina agrícola com o consórcio!</p>
      </div>
      <div class="pode-fazer">
        <h1>O que você pode fazer com seu crédito?</h1>
        <div class="pode-fazer-containers">
          <div class="beneficios" id="fazer-1">
            <h1>Consórcio Imobiliário</h1>
            <p>Com parcelas a partir de <span>R$310,00</span> e prazo de até <span>200 meses</span>, você pode: </p>
            <ul>
              <li>Comprar imóveis residenciais e comerciáis, novos, usados ou na planta, terrenos, casa na praia e no campo</li>
              <li>Utilizar o seu crédito para construir ou reformar.</li>
              <li>Quitar seu financiamento imobiliário.</li>
            </ul>
          </div>
          <div class="beneficios" id="fazer-2">
            <h1>Consórcio Veículos Leves</h1>
            <p>Com parcelas a partir de R$225,00 e prazo de até 80 meses, você pode:</p>
            <ul>
              <li>Comprar veículos 0km.</li>
              <li>Adquirir veículos com até 8 anos de uso.</li>
              <li>Quitar seu financiamento de veículos.</li>
            </ul>
          </div>
          <div class="beneficios" id="fazer-3">
            <h1>Consórcio de Veículos Pesados</h1>
            <p>Com parcelas a partir de R$2.360,00 e prazo de até 100 meses, você pode:</p>
            <ul>
              <li>Comprar ônibus e caminhão, novo ou usado com até 10 anos de fabricação.</li>
              <li>Adquirir trator, máquinas e equipamentos agrícolas.</li>
              <li>Utilizar o seu crédito para comprar aeronaves e embarcações.</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="vantagens-consorcio">
        <h1>Vantagens dos nossos consórcios</h1>
        <div id="vantagens-containers">
          <div class="vantagens" id="vantagem-1">
            <img src=https://cdn.discordapp.com/attachments/1101250377950707794/1137064341649236079/semjuros.png width=180px height=180px>
            <h1>Sem juros</h1>
            <p>Escolha a oferta ideal para você sem pagar juros. Pague somente a taxa de administração, que é diluida no prazo contratado.</p>
          </div>
          <div class="vantagens" id="vantagem-2">
            <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1137064341854765106/taxas.png" width="180px" height="180px">
            <h1>Reduza taxas e impostos</h1>
            <p>Você pode usar até 10% do valor do crédito para pagar despesas referentes ao bem, como seguros, tributos e registros.</p>
          </div>
          <div class="vantagens" id="vantagem-3">
            <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1137064341435322418/figura3.png" width="180px" height="180px">
            <h1>Amplia e renove sua frota</h1>
            <p> Com o consórcio de veículos pesados, você pode adquirir ônibus e caminhões com até 100 meses para pagar. </p>
          </div>
        </div>
      </div>
      <hr style="color: #000000;"> 
      <div id="saiba-mais">
        <h1>Saiba mais sobre o <br>consórcio</h1>

        <div id="all-faq-cards">
        <div class="faq-card">
          <details>
            <summary>Quem pode fazer o consórcio?</summary>
            <div> Qualquer pessoa, com 18 anos ou mais. Mas lembre-se: você vai fazer um compromisso financeiro, então, antes de tudo, analise se suas escolhas estão compatíveis com a sua renda. Desde o valor do crédito até o valor que você terá que desembolsar mensalmente. </div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>O que é cobrado na parcela do seu consórcio?</summary>
            <div> No consórcio você não paga juros. Veja para o que o seu dinheiro será destinado:<br><br><br>
              <b>Fundo Comum:</b> é o valor que será reunido para a compra do bem. É desse fundo que tiramos o crédito que será destinado aos contemplados e, havendo saldo no encerramento do grupo, será devolvido aos consorciados ativos e excluídos.<br><br> 
              <b>Fundo de Reserva:</b> esse fundo é essencial para manter a saúde financeira do grupo. O valor é destinado a uma reserva para casos de imprevistos como cobrir uma eventual falta de dinheiro no Fundo Comum e, havendo saldo no encerramento do grupo, o mesmo será devolvido aos consorciados ativos.<br><br>
              <b>Taxa de Administração:</b> é o valor que a gente cobra para administrar seu consórcio. A Taxa de Administração é diferente dos juros cobrados em outras modalidades, por isso é bem menor. Ela é dividida durante o tempo contratado: um pedacinho em cada parcela.<br><br>
              <b>Seguro DFI - Danos Físicos ao Imóvel:</b> exclusivo para o Consórcio Imobiliário, esse seguro é cobrado somente após a aquisição, construção, reforma e/ou ampliação do imóvel.<br><br>
              <b>Seguro Prestamista:</b> esse seguro é opcional e cobrado a partir da 2ª parcela. Em caso de morte ou invalidez total e permanente do titular, o seguro quita o saldo devedor do consórcio. Trata-se de uma escolha importante para quem quer cuidar da família. Se preferir, consulte as condições gerais: <b>Consórcio Imobiliáro</b> ou <b>Consórcio de Veículos.</b> </div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>O que são as assembleias?</summary>
            <div>Todos os meses é realizada uma reunião chamada Assembleia Geral Ordinária - AGO. É na AGO que acontece o sorteio e a avaliação dos lances que definirão os contemplados daquele respectivo mês.<br><br>

              Temos também a Assembleia Geral Extraordinária - AGE, que pode ser convocada a qualquer momento, pela empresa ou pelo grupo, quando existir a necessidade de definir assuntos de interesse de todos.</div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>Qual a diferença entre sorteio e lance?</summary>
            <div> <b>Sorteio:</b> a apuração da cota sorteada é realizada mensalmente, com base no resultado da Loteria Federal anterior à data da assembleia. Mas lembre-se, caso não esteja com a parcela em dia, você não terá direito ao sorteio.<br><br>

              <b>Lance:</b> é uma forma de você aumentar a chance de ser contemplado antecipando parcelas do seu consórcio e pode ser feito de duas formas:<br><br>
              
              <b>Lance fixo:</b> você oferece o valor fixo de 20% do que ainda falta pagar do seu consórcio. Será contemplado aquele que, entre todos, possuir a cota mais próxima da cota sorteada pela Loteria Federal.<br><br>
              
              <b>Lance livre:</b> neste tipo de lance você pode ofertar qualquer valor maior que sua parcela mensal. O lance é convertido em percentual e aquele que representar o maior valor será o contemplado. Em caso de empate, a cota contemplada será a de número mais próximo à cota sorteada.<br><br>
              
              <b>Ah! Você pode ofertar lance fixo e livre na mesma assembleia. Aí terá três chances de ser contemplado - sorteio, lance fixo e lance livre. Quando for contemplado a gente entra em contato :)
              
              Sabia que, em alguns grupos, você tem a opção de usar o valor do crédito para pagar o lance?
              Utilize até 50% para o imobiliário e até 30% para veículos, conforme o seu contrato de adesão.</b><br><br>
              
              É possível utilizar parte do valor do crédito para dar o lance sem precisar ter dinheiro. Mas, se você tem o dinheiro e quer complementar a oferta com o valor do crédito, suas chances de contemplação aumentam. Após a contemplação, você terá um novo valor de crédito disponível e pode optar por reduzir o valor das parcelas ou o prazo.
              </div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>Como é atualizado o valor do crédito?</summary>
            <div> O valor do crédito é corrigido de acordo com o Índice Nacional de Preços ao Consumidor - INPC. A cada 12 assembleias do grupo, o crédito é atualizado com ajuste da parcela e do saldo devedor. Assim, seu poder de compra é mantido. </div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>O que acontece quando eu sou contemplado?</summary>
            <div> Após sua contemplação, o valor do seu crédito será liberado depois da aprovação da análise de crédito e de garantia. Por isso, se você estiver com alguma restrição no nome, vai precisar regularizar sua situação. No Consórcio Imobiliário, o vendedor do imóvel também precisa estar sem restrições para que tudo seja aprovado. </div>
          </details>
        </div>
        <div class="faq-card">
          <details>
            <summary>Como usar o FGTS no Consórcio Imobiliário?</summary>
            <div>Existem algumas opções para usar o <b>Fundo de Garantia do Tempo de Serviço (FGTS)</b> no seu Consórcio Imobiliário, de acordo com as regras do próprio fundo. São elas:<br><br>

              <ul>
              <li>
                Complementar o pagamento do valor do imóvel a ser adquirido;
              </li>
              </ul>
            </div>
          </details>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  }
}
</script>
<style scoped>
#main-img {
  margin-top: 0px;
  width: 100%;
  height: 400px;
}

h1 {
    font-family: Poppins;
    font-weight: bold;
}

#all-containers {
    text-align: center;
}

#entenda-consorcio {
    margin: 4rem;
    padding: 3rem;
    padding-bottom: 3rem;
    -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

#cred-description {
  color: white;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

#entenda-consorcio img {
    float: left;
    vertical-align: top;
    margin-left: -2rem;
}

#entenda-consorcio h1 {
    font-family: Poppins;
    font-weight: bold;
    text-align: left;
}

#entenda-consorcio p {
    line-height: 1.5;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 2rem;
    font-size: 18px;
    text-align: left;
}

.pode-fazer {
    padding: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
    text-decoration: none;
    list-style: none;
    -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

.beneficios ul {
    list-style-position: inside;
    padding-left: 0;
}

.beneficios {
    margin: 0 auto;
    display: inline-block;
    overflow: hidden;
    height: 280px;
    width: 360px;
    margin-left: 1rem;
    padding: 1rem;
    text-align: left;
}

#img-text {
    position: absolute;
    margin: 2rem;
    margin-top: 12rem;
}

#img-text h1{
    color: rgb(255, 145, 0);
}
.beneficios h1 {
    font-size: 20px;
    font-weight: bold;
}

#vantagens-consorcio {
    padding: 1rem;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-bottom: 4rem;
    text-decoration: none;
    list-style: none;
    -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

.vantagens ul {
    list-style-position: inside;
    padding-left: 0;
}


.btn {
  background-color: rgb(0, 25, 136);
  color: white;
  font-weight: bold;
}
.btn:hover {
  background-color: rgb(255, 145, 0);

}
  


.vantagens {
  margin: 0 auto;
    display: inline-block;
    overflow: hidden;
    height: 450px;
    width: 360px;
    margin-left: 1rem;
    padding: 1rem;
    text-align: left;
}

.vantagens h1 {
    font-size: 22px;
    font-weight: bold;
}

.container h1{
  color: #fff;
  text-align: center;
}

details p {
    font-size: 16px;
}

#saiba-mais {
    margin-top: 4rem;
}

#saiba-mais h1 {
    float: left;
    font-size: 36px;
    margin-left: 4rem;
    color: rgb(0, 25, 136);
    font-weight: bold;
}

#all-faq-cards {
    /*float: right;  --> jeito antigo de alinhar pra direita (faz o footer ficar bugado)
    margin-right: 15rem;*/
    margin-left: 50%;
    text-align: left;
    margin-bottom: 0.5rem;
}

#vantagens-consorcio {
}




details{
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  border-bottom: 1px solid #000000;
  font-family: Poppins;
  width: 780px;
  padding-top: 1.5rem;
  padding-bottom: 2.6rem;
}

summary {
  padding: .5em 1.3rem;
  list-style: none;
  transition: height 1s ease;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after{
  content: "     \002B";
}

details[open] summary {
    margin-bottom: .5em;
}

details[open] summary:after{
  content: "    -";
  border-bottom-color: #afbcc0;
}

details[open] details:after {
    padding-bottom: 0;
}

details[open] div{
    line-height: 1.5rem;
    padding: 1rem;
    text-align: left;
    padding-bottom: 2rem;
  background-color: rgba(228, 229, 255, 0.3);
}


.faq-card {
    width: 600px;
}

@media (max-width: 600px) {
    #vantagens-consorcio h1{
      font-size: 24px;
    }
    #vantagens-containers {
      width: auto;
      text-align: center;
      padding: 0;
    }
    #saiba-mais h1 {
      margin: 0;
      padding-right: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      font-size: 24px;
    }
    .vantagens img {
      display: none;
    }

    .vantagens- {
      margin: 0;
      padding: 0;
      font-size: 5px;
    }
    .beneficios {
      width: 320px;
    }
    #main-img{
        height: 145px;
    }

    #img-text {
        margin-top: 4.5rem;
    }

    #img-text h1 {
        font-size: 20px;
    }

    #img-text p {
        font-size: 16px;
    }
    
    #entenda-consorcio {
      width: auto;
      height: auto;
      padding: 1.5rem;
      margin: 1rem;
      margin-bottom: 4rem;
      -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
  }

  #entenda-consorcio img {
    display: none;
  }

  #entenda-consorcio h1 {
      text-align: center;
      font-size: 24px;
  }

  #entenda-consorcio p {
      line-height: 1.5;
      font-family: Arial, Helvetica, sans-serif;
      padding-top: 2rem;
      font-size: 18px;
      text-align: left;
  }

  .pode-fazer {
      width: auto;
      height: auto;
      padding: 1.5rem;
      margin: 1rem;
      margin-bottom: 4rem;
      -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
  }

  .pode-fazer h1 {
    font-size: 24px;

  }
  .beneficios {
    margin: 0 auto;
    display: inline-block;
    overflow: hidden;
    height: auto;
    width: auto;
    margin-left: 1rem;
    padding: 1rem;
    text-align: left;
  }


  #vantagens-consorcio {
    width: auto;
      height: auto;
      padding: 1.5rem;
      margin: 1rem;
      -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
      box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
  }

    .vantagens ul {
        list-style-position: inside;
        padding-left: 0;
    }

    .vantagens {
        margin: 0 auto;
        display: inline-block;
        overflow: hidden;
        height: auto;
        width: auto;
        margin-left: 1rem;
        padding: 1rem;
        text-align: left;
    }

    .vantagens h1 {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        margin: 1rem;
    }

    /* saiba mais */
    #saiba-mais {
        margin-top: 4rem;
    }

    #saiba-mais h1 {
        font-size: 28px;
        text-align: center;
        margin: auto;
        color: rgb(0, 92, 169);
        font-weight: bold;
    }

    #all-faq-cards {
        margin: auto;
        padding: auto;
    }
    .faq-card {
        width: auto;
        margin: auto;
        padding: 0;
    }
    details{
      cursor: pointer;
      background-color: #ffffff;
      color: #000000;
      width: auto;
      margin: auto;
      font-size: 16px;
      border-bottom: 1px solid #000000;
      font-family: Poppins;
    }
    details p {
      font-size: 16px;
    }
    summary {
      padding: .5em 1.3rem;
      list-style: none;
      transition: height 1s ease;
    }

    summary::-webkit-details-marker {
      display: none;
    }

    summary:after{
      content: "     \002B";
    }

    details[open] summary {
        margin-bottom: .5em;
    }

    details[open] summary:after{
      content: "    -";
      border-bottom-color: #afbcc0;
    }

    details[open] details:after {
        padding-bottom: 0;
    }

    details[open] div{
        line-height: 1.5rem;
        padding: 1rem;
        text-align: center;
        padding-bottom: 2rem;
      background-color: rgba(228, 229, 255, 0.3);
    }

    #consorcio-main-img {
      margin-top: 0px;
      height: 100px;
    }
}

</style>
<style>

</style>