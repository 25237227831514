<template>
    <v-app>
        <div id=cartoes>
            <div id="main-header">
                <div id="img-text">
                    <h1>Crédito Consignado</h1>
                    <p>Temos a opção de empréstimo consignado ideal para você.</p>
                    <v-btn target="_blank" href="https://api.whatsapp.com/send?phone=551335917095&text=Ol%C3%A1,%20quero%fazer%20uma%20simulação%20de%20crédito%20consignado." class="btn">Simule aqui seu crédito</v-btn>
                </div>
                <img id=main-img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128068771345596516/image.png"> 
            </div>
        </div>
        <div id=content>
            <div class=container style="margin-top: 1rem;" id="entenda-consignado">
                <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1137043373841186918/business.png">
                <h1>O que é crédito consignado</h1>
                <p>Crédito sem a necessidade de avalista, com vantagens especiais e as melhores condições, para funcionários de empresas e órgãos conveniados, aposentados e pensionistas do INSS</p>
            </div>
            <div id="vantagens-container">
                <h1>Vantagens do crédito consignado</h1>
                    <div id="vantagem1" class="vtg-container">
                        <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-dinheiro-lucro-moeda.png">
                        <h2>Economia</h2>
                        <p>Esqueça a burocracia. Com a gente, você não precisa de avalista e seu crédito fica disponível rapidinho.</p>
                    </div>
                    <div id="vantagem2" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-mao-acordo.png">
                        <h2>Segurança e controle</h2>
                        <p>O valor máximo do empréstimo é calculado de acordo com o seu salário ou benefício e a prestação não ultrapassa 35% do que você recebe</p>
                    </div>
                    <div id="vantagem3" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/Paginas/LT_T027/lista_info_produto_icones_conta_corrente_pf_comodidade.png">
                        <h2>Comodidade</h2>
                        <p>A prestação é descontada diretamente do seu salário ou benefício do INSS e você pode antecipar a liquidação quando quiser.</p>
                    </div>
            </div>
            <div class="container">
                <h1>Condições exigidas</h1>
                <ul>
                    <li>Trabalhar em empresa ou órgão conveniado, ser aposentado ou pensionista permanente do INSS;</li>
                    <li>Para pensionistas do INSS, o benefício deverá ser permanente e permitir a contratação do empréstimo. Sujeito à aprovação do órgão;</li>
                    <li>O valor da prestação não pode ser maior do que 35% do salário ou benefício mensal, a depender da adesão do convênio;</li>
                    <li>As prestações mensais são iguais e descontadas diretamente do salário ou benefício;</li>
                    <li>A contratação está sujeita à aprovação de crédito, confirmação da averbação pelo órgão ou empregador e demais cláusulas contratuais do produto.</li>
                    <li>No caso de Consignado com garantia de FGTS, informamos que esta modalidade está temporariamente suspensa.</li>
                </ul>
            </div>
        </div>
    </v-app>
    </template>
    <script>
    
    </script>
    
<style scoped>
    .container h1 {
        
    }

    .container {
        height: 400px;
    }

    #entenda-consignado {

    }

        
    #entenda-consignado img {
        float: left;
        vertical-align: top;
        width: 240px;
        visibility: visible;
        margin-left: 6rem;
        height: 240px;

    }


    .container {
        padding-bottom:7rem;
    }

    .container p {
        width: 50%;
        margin: auto;
        font-size: 20px;
    }
    .btn {
        background-color: rgb(0, 25, 136);
        color: white;
        font-weight: bold;
    }
    .btn:hover {
        background-color: rgb(255, 145, 0);

    }
    #main-img {
        margin-top: 0px;
        width: 100%;
        height: 400px;
    }
    
    #img-text {
        position: absolute;
        margin: 2rem;
        margin-top: 12rem;
    }
    
    #img-text h1{
        color: rgb(255, 145, 0);
    }
    
    #img-text p {
        color: white;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    #vantagens-container {
    text-align: center;
    width: auto;
        height: auto;
        padding-top: 1rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

    .vtg-container {
        margin: auto;
        display: inline-block;
        overflow: hidden;
        height: 300px;
        width: 320px;
        padding: 1rem;
        text-align: left;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 1rem;
        margin: 1rem;
        
        
    }
    
    .container {
        width: auto;
        height: auto;
        padding-top: 1rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    }
    .container ul {
        margin: auto;
    }



    .container li {
        margin: auto;
        width: 50%;
        margin-top: 1rem;
        font-size: 20px;
        text-align: left;
    }

    @media (max-width: 460px) {
        #main-img{
        height: 145px;
    }

    #img-text {
        margin-top: 4.5rem;
    }

    #img-text h1 {
        font-size: 20px;
    }

    #img-text p {
        font-size: 16px;
    }
    #vantagens-container h1 {
        font-size: 24px;
    }

    .vtg-container {
        width: 250px;
    }

    .container h1 {
        font-size: 24px;
        padding: 1rem;
        text-align: center;
    }

    .container p {
        font-size: 16px;
        padding: 1rem;
        margin: 0;
        width: auto;
    }

    .container li {
        font-size: 16px;
        width: auto;
        padding: 2rem;
        list-style-position: inside;
    }
    #entenda-consignado img {
        visibility: hidden;
    }
    }


    </style>