<template>

<div id="left-menu">
    <div id="corretor-informations">
        <img src="" alt="Avatar do corretor" id="corretor-avatar">
        <p id="corretor-name">Paulo Afonso</p>
    </div>
</div>

</template>

<script>
export default {
    data () {
        return {
            corretor_name: "Paulo Afonso",
            corretor_img_url: `https://ui-avatars.com/api/?name=${corretor_name}`
        }
    }
}
</script>


<style scoped>
</style>