<template>
  <v-app>
    <div id="home">
      <div id="main-img">
        <h1 class="text-block"><span style="color: rgb(255, 145, 0)">A escolha é sua</span><br>
          mas a aprovação do crédito, deixa com a gente.<br>
          <v-btn to="/simulador-credito" class="btn">Simule aqui seu crédito</v-btn>
        </h1>
        <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1126260127775854772/Banner_Home_3.png" >
      </div>
      <div id="conteudo">
        <div id="sobre-nos">
          <div id="somos">
            <h1>Somos a A2 Consultoria</h1>
            <p>
              Nossa empresa foi fundada com a missão de tornar a experiência de crédito imobiliário excepcional para nossos clientes e parceiros. Colocamos as necessidades e os desejos dos nossos clientes no centro de cada decisão que tomamos. Nosso objetivo é não apenas oferecer soluções financeiras, mas também criar relações de longo prazo baseadas em confiança, respeito e integridade.<br><br>
              Nossos sócios acumulam muitos anos de experiência no segmento de crédito imobiliário. Essa vasta expertise nos permite oferecer um serviço sólido e eficiente aos nossos clientes, adotamos uma abordagem personalizada para cada caso, oferecendo soluções de crédito imobiliário que se adequam às necessidades específicas de nossos clientes. Conhecemos os meandros do mercado e, com essa compreensão, estamos prontos para orientar nossos clientes em suas decisões financeiras mais importantes.<br><br>
              Nossa equipe é composta por profissionais altamente qualificados e apaixonados pelo que fazem. Cada membro da equipe compartilha do mesmo compromisso em fornecer o melhor atendimento possível e entende a importância de proporcionar uma experiência positiva e tranquila durante todo o processo de aquisição de crédito imobiliário.<br><br>
            </p>
          </div>
          <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128072898872094720/beatrix.jpg">
        </div>
        <div id="banners-container">
          <div id="banner1" class=single-banners>
            <h1 class="banner-title">Crédito Imobiliário</h1>
              <p class="banner-description banner-text">Temos a opção de crédito imobiliário ideal pra você.</p>
              <a class="banner-description banner-btn" href="/credito-imobiliario">Saiba mais</a>
            <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128068771345596516/image.png">

          </div>
          <div id=vantagens>
            <h1>Vantagens do nosso crédito imobiliário</h1>
            <div id="vantagens-container">
                <div id="vantagem1" class="container">
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-dinheiro-lucro-moeda.png">
                    <h2>Economia</h2>
                    <p>Financie até <b>90% do valor do imóvel</b> e tenha até 35 anos para pagar. Você ainda pode para usar seu FGTS para amortizar e pagar parte das prestações.</p>
                </div>
                <div id="vantagem2" class=container>
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-casa-home.png">
                    <h2>Oportunidade</h2>
                    <p>Possuímos linhas de financiamento para comprar, construir ou reformar seu imóvel residencial ou comercial.</p>
                </div>
                <div id="vantagem3" class=container>
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-documento-juros-percentual-desconto.png">
                    <h2>Flexibilidade</h2>
                    <p>Escolhe a taxa de juros que melhor se adequa a seu perfil. A taxa pode ser prefixada, atrelada ao índice da Poupança ou indexada pela TR ou IPCA.</p>
                </div>
                <div id="vantagem4" class="container">
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-mao-acordo.png">
                    <h2>Facilidade</h2>
                    <p>Faça uma <a href="/simulador-credito">simulação de financiamento</a> e saiba mais detalhes sobre prazos e condições.</p>
                </div>
              </div>
            </div>
          <div id="banner2" class="single-banners">
            <h1 class="banner-title">Consórcio</h1>
            <p class="banner-description banner-text">Comprar, construir ou reformar sua casa.</p>
            <a class="banner-description banner-btn" href="/consorcio">Saiba mais</a>
            <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128069165186568232/image1.png">
          </div>
          <div id="double-banner">
            <div id="banner3">
              <h1 class="banner-title">Cartões</h1>
              <p class="banner-description banner-text">Temos um cartão certo para o que você precisar.</p>
              <a class="banner-description banner-btn" href="/cartao-de-credito">Saiba mais</a>
              <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128069488399618108/image2.png">
            </div>
            <div id="banner4">
              <h1 class="banner-title">Crédito Consignado</h1>
              <p class="banner-description banner-text">Temos a opção de crédito ideal pra você.</p>
              <a class="banner-description banner-btn" href="/credito-consignado">Saiba mais</a>
              <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128069796416720976/image3.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Topbar from '../components/Topbar.vue';


export default {
  name: 'HomeView',
  components: {
    Topbar
  }
}
</script>
<style scoped>


.btn {
  background-color: rgb(0, 25, 136);
  color: white;
  font-weight: bold;
}
.btn:hover {
  background-color: rgb(255, 145, 0);

}
#home {
  margin-top: 0px;
}

#main-img {
  position: relative;
}

.text-block {
  position: absolute;
  margin: 2rem;
  font-family: Poppins;
  bottom: 5px;
  color: white;
  font-size: 30px;
}

#main-img img {
  width: 100%;  
  height: 400px;
}

#sobre-nos {
  margin: 1rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
  
}

#somos {
  text-align: left;
  display: inline-block;
}

#conteudo {
  margin: auto;
  text-align: center;
}

#vantagens h1 {
    text-align: center;
    margin-bottom: 1rem;
}


#vantagens-container {
    text-align: center;
}

.container {
    margin: auto;
    display: inline-block;
    overflow: hidden;
    height: 300px;
    width: 310px;
    padding: 1rem;
    text-align: left;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 1rem;
    margin: 0.5rem;
    margin-bottom: 3rem;
    
}


.container img {
    width: 36px;
    height: 31px;
}

#somos p {
  width: 500px;
  display: inline-block;
  line-height: 1.3;
  text-align: justify;
  text-justify: distribute;
}

#somos br {

}


#somos {

}

#sobre-nos img {
  height: 450px;
  width: 700px;
  display: inline-block;
  border-radius: 1.5rem;
  margin-left: 2rem;
  margin-top: 2rem;
  vertical-align: top;
}


#banner1, #banner2{
  width: 1280px;
  margin: auto;
}

#banner1 img, #banner2 img {
  width: 1280px;
  height: 400px;
  margin-bottom: 1rem;
  border-radius: 1.5rem;
}

.banner-title {
  position: absolute;
  margin-left: 1rem;
  margin-top: 15rem;
  font-family: Poppins;
  color: rgb(255, 145, 0);
  font-size: 30px;
}



.banner-description {
  position: absolute;
  margin: 1rem;
  margin-top: 1rem;
}


#banner1 img {

}

.banner-text {
  margin-top: 18rem;
  color: #fff;
}

.banner-btn {
  margin-top: 19.5rem;
  color: rgb(0, 25, 136);
  text-decoration: none;
  font-weight: bold;

}

.banner-btn:hover {
  color: rgb(255, 145,0 );
  cursor: pointer;
}

#double-banner {
  text-align: center;
  margin: auto;
}

#banner3, #banner4 {
  display: inline-block;
  margin: auto;
  margin-left: 10px;
  margin-right: 10px;
  width: 630px;
}


#banner3 .banner-text, #banner4 .banner-text {
  margin-top: 10rem;
}

#banner3 .banner-title, #banner4 .banner-title {
  margin-top: 7rem;
}

#banner3 .banner-btn, #banner4 .banner-btn {
  margin-top: 11.5rem;
}


.banner-title {
  color: rgb(255, 145, 0)
}


#banner3 img, #banner4 img {
  margin: auto;
  border-radius: 1.5rem;
  width: 630px;
  height: 300px;
}



 
/* media query celular */
@media  (max-width: 480px) {
  /* imagem principal responsiva */
  #main-img img {
    height: 140px;
  }


  .text-block {
    margin: 1rem;
    top: 0px;
    left: 40px;
    font-size: 18px;
  }
  /* */

  /* empresa informações responsiva */
  #sobre-nos img {
    margin-top: 1rem;
    height: 200px;
    margin: 0;
    margin-top: 1rem;
    width: 325px;
  }

  #somos h1 {
    font-size: 22px;
  }

  #somos p {
    font-size: 14px;
    width: 320px;
  }
  /* */

  /* banners responsivos */
  #vantagens {
    display: none;
  }


  .banner-title {
    margin-top: 4rem;
    font-size: 18px;
  }

  .banner-description {
    font-size: 12px;
    margin-top: 6rem;
  }

  .banner-btn {
    margin-top: 7.5rem;
  }

  #banner3 .banner-title, #banner4 .banner-title {
    margin-top: 4rem;
  }

  #banner3 .banner-description, #banner4 .banner-description {
    margin-top: 6rem;
  }

  #banner3 .banner-btn, #banner4 .banner-btn {
    margin-top: 7.5rem;
  }
  #banner1, #banner2, #banner3, #banner4{
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }
  #banner1 img, #banner2 img, #banner3 img, #banner4 img {
    width: 325px;
    height: 170px;
  }

  #banner1 .banner-title {}

  #banner4 {
    margin-top: 1rem;
  }
}

/* media query tablet */
@media (max-width: 1024px) {

}
</style>
