<template>
<v-app>
    <div id="credito-imobiliario">
        <div id="main-header">
            <div id="img-text">
                <h1>Crédito Imobiliário</h1>
                <p id="cred-description">Temos a opção de crédito imobiliário ideal pra você.</p>
                <v-btn to="/simulador-credito" class="btn">Simule aqui seu crédito</v-btn>
            </div>
            <img id=main-img src="https://cdn.discordapp.com/attachments/1101250377950707794/1128068771345596516/image.png"> 
        </div>
        <div id=vantagens>
            <h1>Vantagens do nosso crédito imobiliário</h1>
            <div id="vantagens-container">
                <div id="vantagem1" class="container">
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-dinheiro-lucro-moeda.png">
                    <h2>Economia</h2>
                    <p>Financie até <b>90% do valor do imóvel</b> e tenha até 35 anos para pagar. Você ainda pode para usar seu FGTS para amortizar e pagar parte das prestações.</p>
                </div>
                <div id="vantagem2" class=container>
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-casa-home.png">
                    <h2>Oportunidade</h2>
                    <p>Possuímos linhas de financiamento para comprar, construir <br>ou reformar seu imóvel residencial ou comercial.</p>
                </div>
                <div id="vantagem3" class=container>
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-documento-juros-percentual-desconto.png">
                    <h2>Flexibilidade</h2>
                    <p>Escolhe a taxa de juros que melhor se adequa a seu perfil. A taxa pode ser prefixada, atrelada ao índice da Poupança ou indexada pela TR ou IPCA.</p>
                </div>
                <div id="vantagem4" class="container">
                    <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-mao-acordo.png">
                    <h2>Facilidade</h2>
                    <p>Faça uma <a href="/simulador-credito">simulação de financiamento</a> e saiba mais detalhes sobre prazos e condições.</p>
                </div>
                <!--
                <div id="vantagem5" class="container">
                    <img src="https://www.caixa.gov.br/PublishingImages/Paginas/LT_T027/lista_info_produto_icones_conta_corrente_pf_comodidade.png">
                    <h2>Comodidade</h2>
                    <p>Você acompanha seu contrato de forma totalmente online.</p>
                </div>
                -->
            </div>
            <div id="passo-a-passo">
                <h1>Passo a passo para comprar seu imóvel</h1>
                <div id="passos-containers">
                    <div id="passo1">
                        <h1>1. Simulação Crédito</h1>
                        <div class="passo-description">
                            <ul>
                                <li>1.1 Coleta de dados pessoais</li>
                                <li>1.2 Simulação do valor do crédito, juros e prestação</li>
                                <li>1.3 Envio da simulação ao cliente para aprovação</li>
                            </ul>
                        </div>
                    </div>
                    <div id="passo2">
                        <h1>2. Análise de Crédito</h1>
                        <div class="passo-description">
                            <ul>
                                <li>Cadastro no sistema dos dados pessoais</li>
                                <li>Aprovação crédito imobiliário</li>
                                <li>Solicitação da documentação do vendedor e imóvel</li>
                            </ul>
                        </div>
                    </div>

                    <div id="passo3">
                        <h1>3. Laudo Engenharia</h1>
                        <div class="passo-description">
                            <ul>
                                <li>Solicitação da vistoria do Engenheiro</li>
                                <li>Avaliação e elaboração do laudo pelo Engenheiro do banco</li>
                                <li>Liberação laudo de engenharia</li>
                            </ul>
                        </div>
                    </div>
                    <div id="passo4">
                        <h1>4. Análise Jurídica</h1>
                        <div class="passo-description">
                            <ul>
                                <li>Envio da documentação do vendedor, comprador e imóvel para o Jurídico</li>
                                <li>Analise de documentação</li>
                                <li>Aprovação do jurídico</li>
                            </ul>
                        </div>
                    </div>
                    <div id="passo5">
                        <h1>5. Assinatura do contrato</h1>
                        <div class="passo-description">
                            <ul>
                                <li>Assinatura do contrato</li>
                                <li>Pagamento Escritura/ITBI e seguros</li>
                                <li>Registro do contrato no cartório de imóvel</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src="https://cdn.discordapp.com/attachments/1101250377950707794/1130608410933592084/fluxo2.png">
            
            </div>
        </div>
    </div>
</v-app>
</template>
<style scoped>

#passos-containers {
    display: none;
}

#main-img {
    margin-top: 0px;
    width: 100%;
    height: 400px;
}

#img-text {
    position: absolute;
    margin: 2rem;
    margin-top: 12rem;
}

#img-text h1{
    color: rgb(255, 145, 0);
}

#cred-description {
    color: white;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

#passo-a-passo {
    margin:auto;
    margin-top: 4rem;
    text-align: center;
}

.btn {
  background-color: rgb(0, 25, 136);
  color: white;
  font-weight: bold;
}
.btn:hover {
  background-color: rgb(255, 145, 0);

}

#vantagens {
    height: auto;
        padding-top: 1rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        padding-bottom: 2rem;
}

#vantagens h1 {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-size: 36px;
}


#vantagens-container {
    text-align: center;
    height: auto;
}

.container {
    margin: auto;
    display: inline-block;
    overflow: hidden;
    height: 300px;
    width: 320px;
    padding: 1rem;
    text-align: left;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 1rem;
    margin: 1rem;
    
}


.container img {
    width: 36px;
    height: 31px;
}

@media (max-width: 430px) {
    #main-img{
        height: 145px;
    }

    #img-text {
        margin-top: 4.5rem;
    }

    #img-text h1 {
        font-size: 20px;
    }

    #img-text p {
        font-size: 16px;
    }

    .container {
        width: 250px;
    }

    #vantagem2 p {
        font-size: 16px;
    }

    #vantagens h1 {
        font-size: 24px;
    }
    #passo-a-passo h1 {
        font-size: 24px;
    }

    #passos-containers h1{
        font-size: 20px;
    }
    .passo-description {
        border: 1px solid black;
        width: 300px;
        margin: auto;
        text-align: left;
        font-size: 12px;
        padding: 0.2rem;

    }
    #passo-a-passo img {
        display: none;
    }
    .passo-description li {
        list-style: none;
    }
}


</style>
<script>

</script>
