<template>
<link id="wpp-btn" rel="stylesheet" href="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-button.css"> <a id="robbu-whatsapp-button" target="_blank" href="https://api.whatsapp.com/send?phone=5513997344992"> <img src="https://cdn.positus.global/production/resources/robbu/whatsapp-button/whatsapp-icon.svg"></a>
</template>
<script>

</script>
<style scoped>
#wpp-btn {
    position: absolute;
}
</style>
