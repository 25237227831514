<template>
    <v-app>
        <div id=cartoes>
            <div id="main-header">
                <div id="img-text">
                    <h1>Abertura de Contas</h1>
                    <p>Temos a conta corrente certa para você.</p>
                    <v-btn class="btn" href="https://api.whatsapp.com/send?phone=551335917095&text=Ol%C3%A1,%20quero%20abrir%20uma%20conta%20corrente%20caixa." target="_blank">SOLICITE SUA CONTA AGORA!</v-btn>
                </div>
                <img id=main-img src="https://cdn.discordapp.com/attachments/1101250377950707794/1136667616186994698/Copia_de_Banner_Consorcio-modified.png"> 
            </div>
        </div>
        <div id=content>
            <div class=container >
                <h1>Como funciona</h1>
                <p>Com a Conta Corrente CAIXA, você tem a livre movimentação do seu dinheiro contando com toda agilidade e segurança dos serviços e canais CAIXA.</p>
                <p>Acessando sua conta pela internet ou celular você pode verificar saldos e extrato de movimentações, efetuar pagamentos, transferências, DOC, TED, aplicações financeiras em renda fixa ou fundos de investimento, cadastrar contas para débito automático, carregar créditos de celular, fazer jogos de loterias e muito mais, tudo de onde estiver, sem ter que sair de casa ou do trabalho.</p>
                <p>Além disso, você pode sacar, depositar e pagar contas nas casas lotéricas e Correspondentes CAIXA Aqui, além das agências da CAIXA.</p>
            </div>
        </div>
        <div id="vantagens-container">
                <h1>Vantagens da conta corrente CAIXA</h1>
                    <div id="vantagem1" class="vtg-container">
                        <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-dinheiro-lucro-moeda.png">
                        <h2>Praticidade</h2>
                        <p>Transfira sua conta para a CAIXA sem se preocupar com o tempo de relacionamento bancário: a data do seu antigo banco é mantida.​​</p>
                    </div>
                    <div id="vantagem2" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/icones/01-laranja-mao-acordo.png">
                        <h2>Portabilidade de Crédito Salário</h2>
                        <p>Você pode receber seu salário direto em sua conta na CAIXA e ter vantagens como condições especiais nos produtos de crédito, investimentos e Cesta de Serviços.​</p>
                    </div>
                    <div id="vantagem3" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/Paginas/LT_T027/lista_info_produto_icones_conta_corrente_pf_comodidade.png">
                        <h2>Baixas tarifas</h2>
                        <p>Com a cesta de serviços, você conta com as tarifas baixas da CAIXA e ainda pode ter isenção desse valor conforme movimenta sua conta. ​</p>
                    </div>
                    <div id="vantagem4" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/Paginas/LT_T027/lista_info_produto_icones_conta_corrente_pf_comodidade.png">
                        <h2>Crédito fácil</h2>
                        <p>Com a Conta Corrente CAIXA, você sai na frente para obter crédito ou financiar a casa própria com menos burocracia.​​ ​</p>
                    </div>
                    <div id="vantagem5" class=vtg-container>
                        <img src="https://www.caixa.gov.br/PublishingImages/Paginas/LT_T027/lista_info_produto_icones_conta_corrente_pf_comodidade.png">
                        <h2>Comodidade</h2>
                        <p>Livre movimentação dos recursos disponíveis utilizando a agilidade da internet, aplicativo da CAIXA, talão de cheque ou cartão magnético.​​​​</p>
                    </div>
            </div>
            <div class="container">
                <h1>Conta universitária</h1>
                <p>A CAIXA possui uma conta feita só para você, que é universitário.
                Acompanhe seu saldo, coloque seus pagamentos em débito automático, faça transferências e pague seus boletos pelo Internet Banking da CAIXA ou pelo aplicativo no seu celular.
                E para o universitário que possui FIES, as prestações do financiamento podem ser colocados em débito automático.
                </p>
            </div>
            <div class="container">
                <h1>Portabilidade de Crédito Salário</h1>
                <p>Se você é servidor público ou empregado de empresa privada e recebe o salário por outro banco, solicite agora a transferência automática para a CAIXA e receba isenções em tarifas e descontos em taxas para empréstimos, financiamento de casa própria e muito mais. Basta preencher o Formulário de Portabilidade e entregar em uma das agências da CAIXA. Feito isso, seu pedido será analisado e poderá ser efetivado em até dez dias úteis. Quanto maior seu relacionamento com a CAIXA, mais benefícios e vantagens você recebe.
                </p>
            </div>
    </v-app>
    </template>
    <script>
    
    </script>
    
    <style scoped>
    .container {
        height: auto;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
    }
    .btn {
        background-color: rgb(0, 25, 136);
        color: white;
        font-weight: bold;
    }
    .btn:hover {
        background-color: rgb(255, 145, 0);

    }
    
    #main-img {
        margin-top: 0px;
        width: 100%;
        height: 400px;
    }
    
    #img-text {
        position: absolute;
        margin: 2rem;
        margin-top: 12rem;
    }
    
    #img-text h1{
        color: rgb(255, 145, 0);
    }
    
    #img-text p {
        color: white;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    
    .container h1 {
        margin-top: 1.5rem;
        text-align: center;
    }

    .container p {
        width: 50%;
        margin: auto;
        font-size: 20px;
        padding: 0.5rem;
    }
    #vantagens-container {
    text-align: center;
    width: auto;
        height: auto;
        padding-top: 1rem;
        padding-bottom: 2rem;
        margin: 1rem;
        margin-top: 4rem;
        -webkit-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        -moz-box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
        box-shadow: -1px 10px 24px 5px rgba(90,115,166,1);
}

    .vtg-container {
        margin: auto;
        display: inline-block;
        overflow: hidden;
        height: 300px;
        width: 400px;
        padding: 1rem;
        text-align: left;
        border: 1px solid rgb(0, 0, 0);
        border-radius: 1rem;
        margin: 1rem;
        
    }

    @media (max-width: 460px) {

        .container h1 {
            font-size: 24px;
        }

        .vtg-container {
            width: 200px;
        }

        .container p {
            font-size: 15.5px;
            width: 300px;
            padding: 0.5rem;
        }

        #main-img{
        height: 145px;
        }

        #img-text {
            margin-top: 4.5rem;
        }

        #img-text h1 {
            font-size: 20px;
        }

        #img-text p {
            font-size: 16px;
        }
    }
    </style>