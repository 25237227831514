<template>
    <div id="a-empresa-a2" class="content">
        <img class="main-img" src="https://cdn.discordapp.com/attachments/1101250377950707794/1136751436139610122/Banner_Beatrix-modified.png">
        <div id="conteudo">
            <div id="left-menu" class="inline-menu">
                <div class="current-page">{{ currentPage }}</div>
                <ul>
                    <li><v-btn class="btn" to="/empresa">Quem Somos</v-btn></li>
                    <li><v-btn class="btn" to="/localizacao">Localização</v-btn></li>
                    <li><v-btn class="btn" to="/contato">Contato</v-btn></li>
                    <li><v-btn class="btn" to="/certificados">Certificações</v-btn></li>
                </ul>
            </div>
            <div id="contato" class="inline-menu">
                <h1>Contato</h1>
                <p><b>E-mail:</b> {{ email }}</p>
                <p><b>Whatsapp: </b><a class="btn" href="https://api.whatsapp.com/send?phone=551335917095" target="_blank">+55 13 3591-7095</a></p>
                <p><b>Whatsapp: </b><a class="btn" href="https://api.whatsapp.com/send?phone=5513997344992" target="_blank">+55 13 99734-4992</a></p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            currentPage: "Home » Contato",
            email: "atendimento@a2consultoriaimobiliaria.com.br"
        }
    },
}

</script>
<style scoped>

.btn {
    text-decoration: none;
}
.btn:hover {
    color: rgb(255, 145, 0);
}
.inline-menu {
    display: inline-block;
    padding: 1rem 1rem;
    vertical-align: top;
}

.text {
    text-align: left;
    line-height: 1.5;
}
.main-img {
    margin-top: 0px;
    width: 100%;
    height: 300px;
}


#left-menu li {
    list-style: none;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
}


#left-menu a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 25, 136);
    width: 350px;
    height: 60px;
}

#left-menu a:hover {
    color: white;
    background-color: rgb(255, 145, 0);
}

#left-menu a:current {
    color: rgb(255, 145, 0)
}


#localizacao {
    width: 450px;
    height: auto;
}

#conteudo {
    margin: 1rem;
}

.current-page {
    margin-top: -2rem;
    margin-bottom: 1rem;
}

#map {
    height: 450px;
    width: 450px;
    margin: auto;
}

@media (max-width: 430px) {
    #left-menu {
        display: block;
        text-align: center;
    }
    #left-menu a {
        width: 200px;
    }
    .main-img {
        height: 140px;
    }
    #contato {
        width: auto;
    }

    #contato h1{ 
        text-align: center;
        margin-bottom: 2rem;
    }

    #contato p {
        font-size: 13px;
    }
}

</style>
<style>
</style>