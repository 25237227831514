<template>
<v-app>
    <LeftMenu/>s
    <div id="corretor-dashboard">
        <h1>Corretores</h1>
    </div>
</v-app>
</template>


<script>

import LeftMenu from '../components/DashboardLeftMenu.vue'


export default {

    components: {
        LeftMenu,
    },

    data() {
        return {
            
        }
    }
}

</script>

<style scoped>

#corretor-dashboard {
    margin-top: 0px;
}

</style>